import { convivaAppTracker } from '@convivainc/conviva-js-appanalytics';

const configENV = process.env.RUNTIME_ENV || 'local';

const prodEnvs = ['production', 'productionbackup'];

// WARNING: Do not sent local/test environment data to production app gateway
// as it impacts TLB, could trigger false alerts and affects data consistency
const createTrackerConfig = () => {
  if (prodEnvs.includes(configENV)) {
    return {
      appId: 'Pulse5',
      convivaCustomerKey: '1a6d7f0de15335c201e8e9aacbc7a0952f5191d7', // c3.Dryrun
    };
  }
  return {
    appId: 'Pulse5',
    convivaCustomerKey: '405a50207754eb87cdce37b228cb52090f95dfb9', // c3.Scaletest
    trackerConfigUrl: 'https://config-gateway-ui.qe2.conviva.com/js/remote_config.json',
    gatewayUrl: 'https://appgw.qe2.conviva.com/405a50207754eb87cdce37b228cb52090f95dfb9',
    configs: {
      network: {
        encoding: 'none', // gzip for prod
      },
    },
  };
};

export function initSensor() {
  const trackerConfig = createTrackerConfig();
  convivaAppTracker(trackerConfig);
}
